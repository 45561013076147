
.input{
  color: rgba(0, 0, 32, 0.8);
  font-weight: 400;
  overflow-wrap: break-word;
  font-size: 17px;
  line-height: 15px;
  width: 80%;
  border-radius: 4px;
  border: 1px solid #EBEBED;
  position: relative;
  line-height: 34px;
  padding-left: 10px;
  margin: 5px;
}
.text-lr{
  color: rgba(230, 230, 230, 0.9);
  font-weight: 400;
  font-size: 32px;
  text-align: center;
  overflow-wrap: initial;
  line-height: 25px;
  margin-top: 22px;
}
.text-s{
  font-weight: 400;
  font-size: 17px;
  text-align: center;
  color: rgba(230, 230, 230, 0.9);
  line-height: 25px;
  margin-top: 5px;
}
.login-btn:hover{
  margin: 5px;
  text-decoration: none;
  background-color: rgb(59, 59, 59);
  line-height: 1;
  color: aliceblue;
  font-weight: 500;
  font-size: large;
  line-height: 38px;
  width: 80%;
  outline: none;
  border: none;
  border-radius: 5px;
}
.login-btn{
  margin: 5px;
  text-decoration: none;
  background-color: #FCD600;
  line-height: 1;
  color: black;
  font-weight: 550;
  font-size: large;
  line-height: 40px;
  width: 80%;
  outline: 1;
  border: none;
  border-radius: 5px;
}
.form{
  text-align: center;
  margin: 0 auto 0 auto;
  min-width: 350px;
  max-width: 680px;
  width: 60%;
  position: relative;
  padding-top: 10%;;
}

.logout-btn{
  margin: 5px;
  padding: 5px;
  text-decoration: none;
  background-color: rgb(173, 55, 19);
  line-height: 1;
  color: aliceblue;
  font-weight: 500;
  font-size: large;
  line-height: 38px;
  width: auto;
  outline: none;
  border: none;
  border-radius: 5px;
}
.logout-btn:hover{
  margin: 5px;
  text-decoration: none;
  background-color: rgb(41, 115, 139);
  line-height: 1;
  color: aliceblue;
  font-weight: 500;
  font-size: large;
  line-height: 38px;
  width: auto;
  outline: none;
  border: none;
  border-radius: 5px;
}

