body {
  margin: 0;
  font-family: 'Baloo Thambi 2', cursive;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

body {
	font-family: 'montserrat', sans-serif;
	background-color: #223343;
}

header {
	padding: 50px 15px 0px;
}

header h1 {
	color: #FFF;
	font-size: 48px;
	font-weight: 500;
	text-align: center;
}

main {
	width: 100%;
	max-width: 960px;
	margin: 0 auto;
}

section {
	padding: 15px;
}

.searchbox {
	display: block;
	width: 100%;
	padding: 15px;

	border: none;
	outline: none;
	background: none;

	background-color: #EEE;
	border-radius: 8px;

	color: #53565A;
	font-size: 20px;
	font-weight: 500;

	transition: 0.4s ease-out;
}

.searchbox:focus {
	box-shadow: 0px 0px 8px 3px #4484C4;
}

.results {
	display: flex;
	flex-wrap: wrap;
	margin: 0px -15px;
}

.results .result {
	width: 33.333%;
	min-width: 250px;
	padding: 15px;
	display: flex;
	flex-direction: column;
	cursor: pointer;
}

.results .result img {
	display: block;
	width: 100%;
}

.results .result h3 {
	color: #FFF;
	font-size: 20px;
	font-weight: 600;
	padding: 10px;
	background: #445565;
	flex: 1 1 100%;
	transition: 0.4s ease-out;
}

.results .result:hover h3 {
	background: #FFF;
	color: #223343;
}

.popup {
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	background-color: #445565;

	overflow-y: scroll;
}

.popup .content {
	display: block;
	width: 100%;
	max-width: 960px;
	margin: 0 auto;
	padding: 25px;

	color: #FFF;
}

.popup .content h2 {
	font-size: 28px;
	font-weight: 600;
}
.popup .content h2 span {
	font-weight: 300;
	font-size: 20px;
}

.popup .content .rating {
	margin-bottom: 30px;
}

.popup .content .plot {
	display: flex;
	flex-wrap: wrap;
	margin: 0px -15px 30px;
}

.popup .content .plot img {
	flex: 1 1 50%;
	max-width: 300px;
	padding: 0px 15px;
}

.popup .content .plot p {
	flex: 1 1 50%;
	padding: 15px;
}

.popup .content .close {
	display: inline-block;
	padding: 15px 30px;
	font-size: 18px;
	font-weight: 700;

	background: #4484C4;
	color: #FFF;
	border-radius: 8px;
	border: none;
	outline: none;
	appearance: none;
	cursor: pointer;

	transition: 0.4s ease-out;
}

.popup .content .close:hover {
	background: #223343;
}